import { Link } from 'gatsby'
import React from 'react'
import Layout from '../components/layout'

const NotFoundPage = () => (
  <Layout>
    <h1>NOT FOUND</h1>
    <p>The page you are looking for does not exist.</p>
    <Link to="/about" className="button">
      Back to home
    </Link>
  </Layout>
)

export default NotFoundPage
